<template>
  <Layout>
    <h1>Legacy Fetches</h1>
    <p>
      This page allows you to run the synchronous calls to the
      <strong>statements</strong> backend.
    </p>
    <p
      ><strong
        >Netlify imposes a 26s timeout. So please wait more before running it
        again.</strong
      ></p
    >
    <p>
      Select an account:
    </p>
    <ul>
      <li v-for="(useraccount, id, index) in useraccounts" :key="index">
        <b-button @click="fetch(id)"
          >Fetch new operations for {{ id }}</b-button
        >
        <b-spinner v-if="loading"></b-spinner>
      </li>
    </ul>

    <p>
      Problem with fetching records? Check
      <a href="https://git.weboob.org/weboob/weboob/issues"
        >Weboob issues tracker</a
      >
    </p>

    <b-alert :show="showReport">
      <h4 class="alert-heading">{{ reportTitle }}</h4>
      <div v-if="reportSuccess.length > 0">
        <p>Successfull fetches:</p>
        <dl id="example-1">
          <template v-for="item in reportSuccess">
            <dt :key="'dt' + item.login">{{ item.login }}</dt>
            <dd :key="'dd' + item.login">{{ item.message }}</dd>
          </template>
        </dl>
      </div>
      <div v-if="reportFailures.length > 0">
        <p>Failed fetches:</p>
        <dl id="example-2">
          <template v-for="item in reportFailures">
            <dt :key="'dt' + item.login">{{ item.login }}</dt>
            <dd :key="'dd' + item.login"
              ><pre>{{ item.message }}</pre></dd
            >
          </template>
        </dl>
      </div>
      <div v-if="reportError !== ''">
        <pre>{{ reportError }}</pre>
      </div>
    </b-alert>
  </Layout>
</template>

<script>
import axios from 'axios'
import Layout from '@layouts/main.vue'

export default {
  page: {
    title: 'Legacy Fetches',
    meta: [{ name: 'description', content: 'Fetch bank statements' }],
  },
  components: { Layout },
  data: function() {
    return {
      loading: false,
      showReport: false,
      reportTitle: '',
      reportSuccess: [],
      reportFailures: [],
      reportError: '',
    }
  },
  computed: {
    useraccounts() {
      return this.$store.getters['api/get']('useraccounts')
    },
  },
  created() {
    this.$store.dispatch('api/get', 'useraccounts')
  },
  methods: {
    preload: function() {
      this.showReport = true
      this.reportTitle = 'Loading...'
      this.reportSuccess = []
      this.reportFailures = []
      this.reportError = ''
      this.loading = true
    },
    printResult: function(response) {
      // this.logs = JSON.stringify(response)
      this.reportTitle = response.message
      this.reportSuccess = response.success
      this.reportFailures = response.errors
    },
    printError: function(error) {
      // this.logs = JSON.stringify(response)
      this.reportTitle = 'Error'
      this.reportSuccess = []
      this.reportFailures = []
      this.reportError = error // error.response
    },
    realSyncFetch: async function(login) {
      this.preload()
      try {
        const response = await axios.get('/statements/v1/fetch/' + login, {
          // baseURL: this.$store.state.urlStatements,
          // headers: {
          //   Authorization: 'JWT ' + this.$store.state.cbToken,
          // },
        })
        this.printResult(response.data)
      } catch (error) {
        this.printError(error)
      } finally {
        this.loading = false
      }
    },
    // realAsyncFetch: function(login) {
    //   // this.preload()
    //   this.$store.dispatch('statements/post', {
    //     _jv: {
    //       type: 'useraccounts/' + login + '/fetches',
    //     },
    //   })
    // },
    fetch: function(login) {
      this.realSyncFetch(login)
    },
  },
}
</script>
